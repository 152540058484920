import { render, staticRenderFns } from "./MaterialType.vue?vue&type=template&id=a83e4608&scoped=true&"
import script from "./MaterialType.vue?vue&type=script&lang=js&"
export * from "./MaterialType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a83e4608",
  null
  
)

export default component.exports